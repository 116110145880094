import React from 'react'
import axios, { AxiosResponse } from 'axios'
import {
  PaApiResponse,
  ApigatewayPaResponse,
} from '../../models/PlanAndAchievement'
import {
  MpApiResponse,
  MpApiResponseTotal,
} from '../../models/MonthlyProductPlan'
import { ProcurementApiResponse } from '../../models/DeliveryDateReplyInput'

interface PlanAndAchievementApiRequesterProps {
  params: PlanAndAchievementParams
  apiGatewayBaseUrl: string
  accessToken: string
  paSetApiResponse: React.Dispatch<React.SetStateAction<PaApiResponse[]>>
}

interface PlanAndAchievementParams {
  group: string
  text: string
  start: string
  end: string
  actual: boolean
}

interface MonthlyProductPlanApiRequesterProps {
  path: MonthlyProductPlanPath
  apiGatewayBaseUrl: string
  accessToken: string
  mpSetApiResponseTotal: React.Dispatch<
    React.SetStateAction<MpApiResponseTotal[]>
  >
}

interface MonthlyProductPlanPath {
  year: string
}

interface DeliveryDateReplyInputApiRequesterProps {
  apiGatewayBaseUrl: string
  accessToken: string
  setProcurementApiResponse: React.Dispatch<
    React.SetStateAction<ProcurementApiResponse>
  >
}

interface DeliveryDateReplyInputWriteParams {
  supplyer_date: string
}

interface DeliveryDateReplyInputWritePath {
  poId: string
}

interface DeliveryDateReplyInputWriteApiRequesterProps {
  data: DeliveryDateReplyInputWriteParams
  path: DeliveryDateReplyInputWritePath
  apiGatewayBaseUrl: string
  accessToken: string
}

export const planAndAchievementApiRequester = async (
  props: PlanAndAchievementApiRequesterProps
): Promise<void> => {
  const { apiGatewayBaseUrl, params, accessToken, paSetApiResponse } = props
  const response: AxiosResponse<ApigatewayPaResponse> = await axios.get(
    `${apiGatewayBaseUrl}/production`,
    {
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  paSetApiResponse(response.data.product_orders)
}

export const monthlyProductPlanApiRequester = async (
  props: MonthlyProductPlanApiRequesterProps
): Promise<void> => {
  const { apiGatewayBaseUrl, path, accessToken, mpSetApiResponseTotal } = props
  const response: AxiosResponse<MpApiResponse> = await axios.get(
    `${apiGatewayBaseUrl}/monthly-total/${path.year}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  mpSetApiResponseTotal(response.data.total)
}

export const deliveryDateReplyInputApiRequester = async (
  props: DeliveryDateReplyInputApiRequesterProps
): Promise<void> => {
  const { apiGatewayBaseUrl, accessToken, setProcurementApiResponse } = props
  const response: AxiosResponse<ProcurementApiResponse> = await axios.get(
    `${apiGatewayBaseUrl}/procurement`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  setProcurementApiResponse(response.data)
}

export const deliveryDateReplyInputWriteApiRequester = async (
  props: DeliveryDateReplyInputWriteApiRequesterProps
): Promise<void> => {
  const { apiGatewayBaseUrl, accessToken, path, data } = props
  const url = `${apiGatewayBaseUrl}/procurement/${path.poId}`
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  await axios.post(url, data, axiosConfig)
}
