import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import { sumMonthlyProduct } from '../../utils/monthly_product_plan/MonthlyProductPlan'
import { MpSeriesType } from '../../models/MonthlyProductPlan'

interface StackedTableArg {
  title: string
  header: string[]
  series: MpSeriesType[]
}

const sumArray = (array: number[]): number => {
  return array.reduce((a, b) => a + b)
}

const StackedTable: React.FC<StackedTableArg> = ({
  title,
  header,
  series,
}: StackedTableArg) => {
  const sumMonths = sumMonthlyProduct(series)
  const boldStyle = { fontWeight: 'bold' }
  const marginLeftStyle = { paddingLeft: 7 }
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ marginY: 2, marginX: 5, width: 1025 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={boldStyle}> {title} </TableCell>
              {header.map((month) => (
                <TableCell sx={boldStyle} align="right" key={`month-${month}`}>
                  {month}
                </TableCell>
              ))}
              <TableCell sx={{ ...marginLeftStyle, ...boldStyle }}>
                計
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={boldStyle} component="th" scope="row">
                計
              </TableCell>
              {sumMonths.map((datum, datumi) => (
                <TableCell
                  sx={boldStyle}
                  align="right"
                  key={`cell1-${datum}-${datumi}`}
                >
                  {datum}
                </TableCell>
              ))}
              <TableCell sx={{ ...boldStyle, ...marginLeftStyle }}>
                {sumArray(sumMonths)}
              </TableCell>
            </TableRow>
            {series.map((row, rowi) => (
              <TableRow key={`row1-${row.name}-${rowi}`}>
                <TableCell sx={{ color: row.color }} component="th" scope="row">
                  {row.name}
                </TableCell>
                {row.data.map((datum, datumi) => (
                  <TableCell
                    sx={{}}
                    align="right"
                    key={`cell1-${datum}-${datumi}`}
                  >
                    {datum}
                  </TableCell>
                ))}
                <TableCell sx={{ ...marginLeftStyle }}>
                  {sumArray(row.data)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default StackedTable
