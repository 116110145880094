import React from 'react'
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material'

interface Candidate {
  name: string
  value: string
}

interface SelectorProps {
  candidates: Candidate[]
  selectValue?: string
  setSelectValue?: React.Dispatch<React.SetStateAction<string>>
}

const Selector: React.FC<SelectorProps> = (props: SelectorProps) => {
  const { candidates, selectValue, setSelectValue } = props
  if (selectValue == null || setSelectValue == null || candidates == null) {
    return <></>
  }
  const handleSelect = (event: SelectChangeEvent): void => {
    const selectedValue = event.target.value
    setSelectValue(selectedValue)
  }
  return (
    <>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="select-label">種別</InputLabel>
        <Select
          labelId="select-label"
          label="種別"
          value={selectValue}
          onChange={handleSelect}
        >
          {candidates.map((cd, cdi) => (
            <MenuItem value={cd.value} key={`addKey-${cdi}`}>
              {cd.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default Selector
