import React from 'react'
import {
  ProcurementApiResponse,
  ProcurementOrder,
} from '../models/DeliveryDateReplyInput'

interface DefaultContext {
  procurementApiResponse?: ProcurementApiResponse
  setProcurementApiResponse?: React.Dispatch<
    React.SetStateAction<ProcurementApiResponse>
  >
}

interface DeliveryProviderProps {
  children: React.ReactNode
}

const DeliveryContext = React.createContext<DefaultContext>({})
const DeliveryProvider: React.FC<DeliveryProviderProps> = ({ children }) => {
  const [procurementApiResponse, setProcurementApiResponse] = React.useState({
    vendor_id: '',
    vendor_name: '',
    procurement_orders: [] as ProcurementOrder[],
  })

  return (
    <DeliveryContext.Provider
      value={{
        procurementApiResponse,
        setProcurementApiResponse,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  )
}

export { DeliveryProvider, DeliveryContext }
