import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Typography, Box } from '@mui/material'
import {
  apiResponseParser,
  productGroupFilter,
} from '../../../utils/monthly_product_plan/MonthlyProductPlan'
import ProductionGroupSelector from '../../components/ProductionGroupSelector'
import YearSelector from '../../components/YearSelector'
import StackedTable from '../../components/StackedTable'
import { MonthlyContext, initFiscalYear } from '../../../contexts'
import { MpSeriesType } from '../../../models/MonthlyProductPlan'
import { monthOrder } from '../../../fixture/MonthOrder'
import { colorOrder } from '../../../fixture/ProductGroupColorOrder'

const displayMonths = monthOrder.map((m) => m.display)

interface MonthlyChartProps {
  series: MpSeriesType[]
}

const MonthlyChart: React.FC<MonthlyChartProps> = (
  props: MonthlyChartProps
) => {
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'column',
          },
          title: {
            text: '',
          },
          xAxis: {
            categories: displayMonths,
          },
          yAxis: {
            min: 0,
            title: {
              text: '生産本数',
            },
          },
          tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat:
              '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: props.series,
          credits: {
            enabled: false,
          },
          accessibility: {
            enabled: false,
          },
        }}
      />
    </>
  )
}

const MonthlyProductPlan: React.FC = () => {
  const {
    mpYearValue,
    mpSetYearValue,
    mpApiResponseTotal,
    productGroupValue,
    setProductGroupValue,
  } = React.useContext(MonthlyContext)

  const products = apiResponseParser({
    total: mpApiResponseTotal,
    monthOrderNumbers: monthOrder.map((m) => m.month),
    colorOrder,
  })

  const filteredProducts = productGroupFilter({
    productGroupValue,
    products,
  })

  return (
    <>
      <div className="m-10 px-5 py-2 bg-white">
        <Box
          sx={{
            margin: 2,
            display: 'flex',
            alignItems: 'flex-center',
            justifyContent: 'space-between',
          }}
        >
          <Typography className="px-3 py-2" variant="h6">
            月産計画
          </Typography>
          <Box
            sx={{
              margin: 2,
              width: 450,
              display: 'flex',
              alignItems: 'flex-center',
              justifyContent: 'space-between',
            }}
          >
            <ProductionGroupSelector
              products={mpApiResponseTotal}
              productGroupValue={productGroupValue}
              setProductGroupValue={setProductGroupValue}
            />
            <YearSelector
              initFiscalYear={initFiscalYear}
              yearValue={mpYearValue}
              setYearValue={mpSetYearValue}
            />
          </Box>
        </Box>
        <MonthlyChart series={filteredProducts} />
      </div>
      <div className="m-10">
        <StackedTable
          title="製品"
          header={displayMonths}
          series={filteredProducts}
        />
      </div>
    </>
  )
}

export default MonthlyProductPlan
