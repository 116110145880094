export const candidates = [
  {
    name: '全て',
    value: 'all',
  },
  {
    name: '電源ラック',
    value: '電源ラック',
  },
  {
    name: '制御ラック',
    value: '制御ラック',
  },
  {
    name: 'トランスラック',
    value: 'トランスラック',
  },
  {
    name: '操作スタンド',
    value: '操作スタンド',
  },
  {
    name: '小物',
    value: '小物',
  },
]
