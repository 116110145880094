import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from '@mui/material'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import dayjs from '../../../vendors/Dayjs'
import { ProcurementOrder } from '../../../models/DeliveryDateReplyInput'
import { DeliveryContext } from '../../../contexts'
import DeliveryEditModal from '../../../views/components/DeliveryEditModal'

interface DeliveryDateReplyHeader {
  key:
    | 'customer_name'
    | 'po_id'
    | 'seiban'
    | 'item_name'
    | 'qty'
    | 'desired_customer_date'
    | 'supplyer_date'
    | 'shipment_date'
  name: string
}

const procurementParser = (orders: ProcurementOrder[]): ProcurementOrder[] => {
  const dayjsParser = (date: string | undefined): string => {
    const formattedDate = dayjs(date).format('YYYY/MM/DD')
    if (formattedDate != null && formattedDate !== 'Invalid Date') {
      return formattedDate
    } else {
      return '----/--/--'
    }
  }
  return orders.map((order) => {
    order.desired_customer_date = dayjsParser(order.desired_customer_date)
    order.supplyer_date = dayjsParser(order.supplyer_date)
    order.shipment_date = dayjsParser(order.shipment_date)
    return order
  })
}

const DeliveryDateReplyInput: React.FC = () => {
  const initOrder = {
    customer_id: '',
    customer_name: '',
    po_id: '',
    seiban: '',
    item_name: '',
    qty: 0,
    desired_customer_date: '',
    supplyer_date: '',
    shipment_date: '',
  }
  const { procurementApiResponse } = React.useContext(DeliveryContext)
  const [openModal, setOpenModal] = React.useState(false)
  const [modalOrder, setModalOrder] =
    React.useState<ProcurementOrder>(initOrder)

  const openEditModal = (order: ProcurementOrder): void => {
    setModalOrder(order)
    setOpenModal(true)
  }

  const handleClose = (): void => setOpenModal(false)

  if (procurementApiResponse == null) {
    return <></>
  }
  const series = procurementParser(procurementApiResponse.procurement_orders)
  const title = procurementApiResponse.vendor_name
  const headers: DeliveryDateReplyHeader[] = [
    { key: 'customer_name', name: '発注元' },
    { key: 'seiban', name: '型式' },
    { key: 'item_name', name: '品目' },
    { key: 'qty', name: '数量' },
    { key: 'desired_customer_date', name: '計画納期' },
    { key: 'supplyer_date', name: '回答納期' },
    { key: 'shipment_date', name: '出荷' },
  ]
  const boldStyle = { fontWeight: 'bold' }
  return (
    <>
      <DeliveryEditModal
        open={openModal}
        handleClose={handleClose}
        order={modalOrder}
      />
      <div className="m-10">
        <TableContainer component={Paper}>
          <Typography className="px-6 pt-4" variant="h6">
            {title}
          </Typography>
          <Table
            sx={{ marginY: 2, marginX: 5, width: 1025 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    sx={boldStyle}
                    align="left"
                    key={`header-${header.key}`}
                  >
                    {header.name}
                  </TableCell>
                ))}
                <TableCell sx={boldStyle} align="left">
                  編集
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {series.map((row, rowi) => (
                <TableRow key={`row-${rowi}`}>
                  {headers.map((header) => {
                    const value = row[`${header.key}`]
                    return (
                      <TableCell
                        sx={{}}
                        align="left"
                        key={`colum-${rowi}-${header.key}`}
                      >
                        {value}
                      </TableCell>
                    )
                  })}
                  <TableCell sx={{}} align="left">
                    <IconButton onClick={() => openEditModal(row)}>
                      <ModeEditOutlineOutlinedIcon sx={{}} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default DeliveryDateReplyInput
