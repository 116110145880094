import React from 'react'
import { Link } from 'react-router-dom'
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import UserInfoBox from './UserInfoBox'
import { PermissionContext } from '../../contexts'
import { Permission } from '../../models/Permission'

interface LinkItemProps {
  path: string
  title: string
  LinkPermission: 'default' | Permission
  UserPermissions: Permission[] | undefined
}

interface MenuProps {
  children: React.ReactNode
}

const LinkItem: React.FC<LinkItemProps> = (props: LinkItemProps) => {
  let permissions = ['default']
  if (props.UserPermissions !== undefined) {
    permissions = permissions.concat(props.UserPermissions)
  }
  const isActive = permissions.includes(props.LinkPermission)
  return (
    <>
      {isActive && (
        <Link to={props.path}>
          <ListItem button>
            <ListItemIcon>
              <BarChartIcon className="text-gray-200" />
            </ListItemIcon>
            <ListItemText className="font-bold" primary={props.title} />
          </ListItem>
        </Link>
      )}
    </>
  )
}

const Menu: React.FC<MenuProps> = ({ children }: MenuProps) => {
  const { permissions } = React.useContext(PermissionContext)
  return (
    <>
      <div className="flex h-screen">
        <div className="static w-1/6 bg-gray-800 text-gray-200">
          <Typography className="pl-4 pt-4" variant="h5">
            進捗管理システム
          </Typography>
          <Typography className="pl-4 pt-4">計画確認メニュー</Typography>
          <List className="">
            <Divider />
            <LinkItem
              path="/monthly_product_plan/"
              title="月産計画"
              LinkPermission="auth:inner"
              UserPermissions={permissions}
            />
            <Divider />
            <LinkItem
              path="/plan_and_achievement/"
              title="生産計画・実績"
              LinkPermission="auth:inner"
              UserPermissions={permissions}
            />
            <Divider />
            <LinkItem
              path="/delivery_date_reply_input/"
              title="納期回答入力"
              LinkPermission="auth:outer"
              UserPermissions={permissions}
            />
            <Divider />
          </List>
          <div className="absolute bottom-0">
            <UserInfoBox />
          </div>
        </div>
        <div className="w-5/6 h-auto bg-gray-300 overflow-y-scroll">
          <div>{children}</div>
        </div>
      </div>
    </>
  )
}

export default Menu
